<template>
    <div class="alert alert-danger d-flex align-items-center py-2 pe-2" v-if="message">
        <i class="bi bi-exclamation-triangle-fill me-3 fs-4"></i>
        <div class="text-start msd-error-msg pe-2">
            <span>{{ message }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            message: {
                type: String,
                required: false
            }
        }
    };
</script>

<style lang="scss" scoped>
    .msd-error-msg {
        overflow-y: scroll;
        max-height: 300px;

        span {
            display: block;
            overflow-x: hidden;
            white-space: pre-wrap;
        }
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background: #842029;
        border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #bd525b;
    }
</style>
