<template>
    <span class="invalid-feedback" v-if="formModel[type].$invalid && formModel.$dirty">
        {{ message || formModel[type].$message }}
    </span>
</template>

<script>
    // 1. formModel: like 'v$.phoneNumber'
    // 2. type: required | numeric | etc.
    // 3. message: string message to display if validation fails
    // 4. if 'message' not defined, helpers.withMessage should be used to define a localized message, otherwise default one would be used
    export default {
        props: {
            formModel: { type: Object, required: true },
            type: { type: String, required: true },
            message: { type: String, required: false }
        }
    };
</script>
