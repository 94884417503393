<template>
    <input
        type="text"
        :inputmode="inputmode"
        :pattern="pattern"
        :placeholder="placeholder"
        v-model.trim.lazy="field.$model"
        :disabled="disabled"
        :class="{ 'form-control': true, 'is-invalid': field.$error }"
        :id="id"
        :autocomplete="autocomplete"
    />
</template>

<script>
    export default {
        props: {
            field: { type: Object, required: true },
            inputmode: { type: String, required: false, default: null },
            pattern: { type: String, required: false, default: null },
            placeholder: { type: String, required: false },
            disabled: { type: Boolean, required: false, default: false },
            id: { type: String, required: false, default: null },
            autocomplete: { type: String, required: false, default: null }
        }
    };
</script>
